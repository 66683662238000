var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-data-table',{staticClass:"rounded-xl mx-1 mx-sm-5 mx-md-12 mb-5",attrs:{"no-data-text":"No hay datos disponibles.","no-results-text":"No hay datos disponibles.","loading-text":"Cargando datos...","calculate-widths":true,"headers":_vm.schedulesHeaders,"items":_vm.schedulesData,"hide-default-header":"","disable-sort":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"body",fn:function({ items }){return [_c('tbody',[(_vm.$vuetify.breakpoint.smAndUp)?_c('tr',{staticClass:"background-tr-color"},[_c('th',{class:`text-${_vm.schedulesHeaders[0].align}`},[_vm._v(" "+_vm._s(_vm.schedulesHeaders[0].text)+" ")]),_c('th',{class:`text-${_vm.schedulesHeaders[1].align}`},[_vm._v(" "+_vm._s(_vm.schedulesHeaders[1].text)+" ")]),_c('th',{class:`text-${_vm.schedulesHeaders[2].align}`},[_vm._v(" "+_vm._s(_vm.schedulesHeaders[2].text)+" ")]),_c('th',{class:`text-${_vm.schedulesHeaders[3].align}`},[_vm._v(" "+_vm._s(_vm.schedulesHeaders[3].text)+" ")]),_c('th',{class:`text-${_vm.schedulesHeaders[4].align}`},[_vm._v(" "+_vm._s(_vm.schedulesHeaders[4].text)+" ")]),_c('th',{class:`text-${_vm.schedulesHeaders[5].align}`},[_vm._v(" "+_vm._s(_vm.schedulesHeaders[5].text)+" ")])]):_vm._e(),_vm._l((items),function(item,index){return _c('tr',{key:index,class:[
          _vm.$vuetify.breakpoint.xsOnly
            ? 'v-data-table__mobile-table-row'
            : '',
        ]},[_c('td',{class:_vm.$vuetify.breakpoint.xsOnly
              ? 'v-data-table__mobile-row pt-6'
              : ''},[_c('div',{staticClass:"font-weight-bold mr-2 v-data-table__mobile-row__header d-flex d-sm-none"},[_vm._v(" "+_vm._s(_vm.schedulesHeaders[0].text)+" ")]),_c('div',{class:_vm.$vuetify.breakpoint.xsOnly
                ? 'v-data-table__mobile-row__cell'
                : `d-flex justify-${_vm.schedulesHeaders[0].align}`},[_vm._v(" "+_vm._s(item.hour)+" ")])]),_c('v-tooltip',{attrs:{"top":"","disabled":!item.fleb1.isReserved,"color":"grey lighten-3"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('td',_vm._g(_vm._b({class:_vm.$vuetify.breakpoint.xsOnly
                  ? 'v-data-table__mobile-row pt-6'
                  : ''},'td',attrs,false),on),[_c('div',{staticClass:"font-weight-bold mr-2 v-data-table__mobile-row__header d-flex d-sm-none"},[_vm._v(" "+_vm._s(_vm.schedulesHeaders[1].text)+" ")]),_c('div',{class:_vm.$vuetify.breakpoint.xsOnly
                    ? 'v-data-table__mobile-row__cell'
                    : `d-flex justify-${_vm.schedulesHeaders[1].align}`},[(item.fleb1.notAvailable)?_c('span',{staticClass:"grey--text font-weight-bold subtitle-2"},[_vm._v("No disponible")]):(item.fleb1.isBreakFast)?_c('span',{staticClass:"amber--text font-weight-bold subtitle-2"},[_vm._v("Comida")]):(!item.fleb1.isReserved)?_c('span',{staticClass:"accent--text font-weight-bold subtitle-2"},[_vm._v("Disponible")]):_c('v-btn',{staticClass:"font-weight-bold text-capitalize subtitle-2",attrs:{"text":"","small":"","color":"red","to":`/citas/detalle-cita/${item.fleb1.order_id}`}},[_vm._v("Reservado")])],1)])]}}],null,true)},[_c('p',{staticClass:"primary--text font-weight-bold pt-2 mb-1"},[_c('v-icon',{staticClass:"pr-1",attrs:{"color":"primary"}},[_vm._v("mdi-clock-outline")]),_vm._v(" "+_vm._s(item.fleb1.customer?item.fleb1.customer.name:'Sin datos del cliente'))],1),_c('p',{staticClass:"secondary--text mb-1"},[_vm._v(_vm._s(item.fleb1.address?`${item.fleb1.address.street} ${item.fleb1.address.num_ext}`:'Sin datos de dirección'))]),(item.fleb1.address)?_c('p',{staticClass:"secondary--text mb-1"},[_vm._v(_vm._s(item.fleb1.address?`Col. ${item.fleb1.address.neighborhood}, ${item.fleb1.address.city}, ${item.fleb1.address.state}`:'Sin datos de dirección'))]):_vm._e()]),_c('v-tooltip',{attrs:{"top":"","disabled":!item.fleb2.isReserved,"color":"grey lighten-3"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('td',_vm._g(_vm._b({class:_vm.$vuetify.breakpoint.xsOnly
                  ? 'v-data-table__mobile-row pt-6'
                  : ''},'td',attrs,false),on),[_c('div',{staticClass:"font-weight-bold mr-2 v-data-table__mobile-row__header d-flex d-sm-none"},[_vm._v(" "+_vm._s(_vm.schedulesHeaders[2].text)+" ")]),_c('div',{class:_vm.$vuetify.breakpoint.xsOnly
                    ? 'v-data-table__mobile-row__cell'
                    : `d-flex justify-${_vm.schedulesHeaders[1].align}`},[(item.fleb2.notAvailable)?_c('span',{staticClass:"grey--text font-weight-bold subtitle-2"},[_vm._v("No disponible")]):(item.fleb2.isBreakFast)?_c('span',{staticClass:"amber--text font-weight-bold subtitle-2"},[_vm._v("Comida")]):(!item.fleb2.isReserved)?_c('span',{staticClass:"accent--text font-weight-bold subtitle-2"},[_vm._v("Disponible")]):_c('v-btn',{staticClass:"font-weight-bold text-capitalize subtitle-2",attrs:{"text":"","small":"","color":"red","to":`/citas/detalle-cita/${item.fleb2.order_id}`}},[_vm._v("Reservado")])],1)])]}}],null,true)},[_c('p',{staticClass:"primary--text font-weight-bold pt-2 mb-1"},[_c('v-icon',{staticClass:"pr-1",attrs:{"color":"primary"}},[_vm._v("mdi-clock-outline")]),_vm._v(" "+_vm._s(item.fleb2.customer?item.fleb2.customer.name:'Sin datos del cliente'))],1),_c('p',{staticClass:"secondary--text mb-1"},[_vm._v(_vm._s(item.fleb2.address?`${item.fleb2.address.street} ${item.fleb2.address.num_ext}`:'Sin datos de dirección'))]),(item.fleb2.address)?_c('p',{staticClass:"secondary--text mb-1"},[_vm._v(_vm._s(item.fleb2.address?`Col. ${item.fleb2.address.neighborhood}, ${item.fleb2.address.city}, ${item.fleb2.address.state}`:'Sin datos de dirección'))]):_vm._e()]),_c('v-tooltip',{attrs:{"top":"","disabled":!item.fleb3.isReserved,"color":"grey lighten-3"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('td',_vm._g(_vm._b({class:_vm.$vuetify.breakpoint.xsOnly
                  ? 'v-data-table__mobile-row pt-6'
                  : ''},'td',attrs,false),on),[_c('div',{staticClass:"font-weight-bold mr-2 v-data-table__mobile-row__header d-flex d-sm-none"},[_vm._v(" "+_vm._s(_vm.schedulesHeaders[3].text)+" ")]),_c('div',{class:_vm.$vuetify.breakpoint.xsOnly
                    ? 'v-data-table__mobile-row__cell'
                    : `d-flex justify-${_vm.schedulesHeaders[1].align}`},[(item.fleb3.notAvailable)?_c('span',{staticClass:"grey--text font-weight-bold subtitle-2"},[_vm._v("No disponible")]):(item.fleb3.isBreakFast)?_c('span',{staticClass:"amber--text font-weight-bold subtitle-2"},[_vm._v("Comida")]):(!item.fleb3.isReserved)?_c('span',{staticClass:"accent--text font-weight-bold subtitle-2"},[_vm._v("Disponible")]):_c('v-btn',{staticClass:"font-weight-bold text-capitalize subtitle-2",attrs:{"text":"","small":"","color":"red","to":`/citas/detalle-cita/${item.fleb3.order_id}`}},[_vm._v("Reservado")])],1)])]}}],null,true)},[_c('p',{staticClass:"primary--text font-weight-bold pt-2 mb-1"},[_c('v-icon',{staticClass:"pr-1",attrs:{"color":"primary"}},[_vm._v("mdi-clock-outline")]),_vm._v(" "+_vm._s(item.fleb3.customer?item.fleb3.customer.name:'Sin datos del cliente'))],1),_c('p',{staticClass:"secondary--text mb-1"},[_vm._v(_vm._s(item.fleb3.address?`${item.fleb3.address.street} ${item.fleb3.address.num_ext}`:'Sin datos de dirección'))]),(item.fleb3.address)?_c('p',{staticClass:"secondary--text mb-1"},[_vm._v(_vm._s(item.fleb3.address?`Col. ${item.fleb3.address.neighborhood}, ${item.fleb3.address.city}, ${item.fleb3.address.state}`:'Sin datos de dirección'))]):_vm._e()]),_c('v-tooltip',{attrs:{"top":"","disabled":!item.fleb4.isReserved,"color":"grey lighten-3"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('td',_vm._g(_vm._b({class:_vm.$vuetify.breakpoint.xsOnly
                  ? 'v-data-table__mobile-row pt-6'
                  : ''},'td',attrs,false),on),[_c('div',{staticClass:"font-weight-bold mr-2 v-data-table__mobile-row__header d-flex d-sm-none"},[_vm._v(" "+_vm._s(_vm.schedulesHeaders[4].text)+" ")]),_c('div',{class:_vm.$vuetify.breakpoint.xsOnly
                    ? 'v-data-table__mobile-row__cell'
                    : `d-flex justify-${_vm.schedulesHeaders[1].align}`},[(item.fleb4.notAvailable)?_c('span',{staticClass:"grey--text font-weight-bold subtitle-2"},[_vm._v("No disponible")]):(item.fleb4.isBreakFast)?_c('span',{staticClass:"amber--text font-weight-bold subtitle-2"},[_vm._v("Comida")]):(!item.fleb4.isReserved)?_c('span',{staticClass:"accent--text font-weight-bold subtitle-2"},[_vm._v("Disponible")]):_c('v-btn',{staticClass:"font-weight-bold text-capitalize subtitle-2",attrs:{"text":"","small":"","color":"red","to":`/citas/detalle-cita/${item.fleb4.order_id}`}},[_vm._v("Reservado")])],1)])]}}],null,true)},[_c('p',{staticClass:"primary--text font-weight-bold pt-2 mb-1"},[_c('v-icon',{staticClass:"pr-1",attrs:{"color":"primary"}},[_vm._v("mdi-clock-outline")]),_vm._v(" "+_vm._s(item.fleb4.customer?item.fleb4.customer.name:'Sin datos del cliente'))],1),_c('p',{staticClass:"secondary--text mb-1"},[_vm._v(_vm._s(item.fleb4.address?`${item.fleb4.address.street} ${item.fleb4.address.num_ext}`:'Sin datos de dirección'))]),(item.fleb4.address)?_c('p',{staticClass:"secondary--text mb-1"},[_vm._v(_vm._s(item.fleb4.address?`Col. ${item.fleb4.address.neighborhood}, ${item.fleb4.address.city}, ${item.fleb4.address.state}`:'Sin datos de dirección'))]):_vm._e()]),_c('v-tooltip',{attrs:{"top":"","disabled":!item.fleb5.isReserved,"color":"grey lighten-3"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('td',_vm._g(_vm._b({class:_vm.$vuetify.breakpoint.xsOnly
                  ? 'v-data-table__mobile-row pt-6'
                  : ''},'td',attrs,false),on),[_c('div',{staticClass:"font-weight-bold mr-2 v-data-table__mobile-row__header d-flex d-sm-none"},[_vm._v(" "+_vm._s(_vm.schedulesHeaders[5].text)+" ")]),_c('div',{class:_vm.$vuetify.breakpoint.xsOnly
                    ? 'v-data-table__mobile-row__cell'
                    : `d-flex justify-${_vm.schedulesHeaders[1].align}`},[(item.fleb5.notAvailable)?_c('span',{staticClass:"grey--text font-weight-bold subtitle-2"},[_vm._v("No disponible")]):(item.fleb5.isBreakFast)?_c('span',{staticClass:"amber--text font-weight-bold subtitle-2"},[_vm._v("Comida")]):(!item.fleb5.isReserved)?_c('span',{staticClass:"accent--text font-weight-bold subtitle-2"},[_vm._v("Disponible")]):_c('v-btn',{staticClass:"font-weight-bold text-capitalize subtitle-2",attrs:{"text":"","small":"","color":"red","to":`/citas/detalle-cita/${item.fleb5.order_id}`}},[_vm._v("Reservado")])],1)])]}}],null,true)},[_c('p',{staticClass:"primary--text font-weight-bold pt-2 mb-1"},[_c('v-icon',{staticClass:"pr-1",attrs:{"color":"primary"}},[_vm._v("mdi-clock-outline")]),_vm._v(" "+_vm._s(item.fleb5.customer?item.fleb5.customer.name:'Sin datos del cliente'))],1),_c('p',{staticClass:"secondary--text mb-1"},[_vm._v(_vm._s(item.fleb5.address?`${item.fleb5.address.street} ${item.fleb5.address.num_ext}`:'Sin datos de dirección'))]),(item.fleb1.address)?_c('p',{staticClass:"secondary--text mb-1"},[_vm._v(_vm._s(item.fleb5.address?`Col. ${item.fleb5.address.neighborhood}, ${item.fleb5.address.city}, ${item.fleb5.address.state}`:'Sin datos de dirección'))]):_vm._e()])],1)})],2)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }