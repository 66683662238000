var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-data-table',{staticClass:"rounded-xl mx-1 mx-sm-5 mx-md-12 mb-5",attrs:{"no-data-text":"No hay datos disponibles.","no-results-text":"No hay datos disponibles.","loading-text":"Cargando datos...","calculate-widths":true,"headers":_vm.extraordinarySchedulesHeaders,"items":_vm.extraordinarySchedulesData,"hide-default-header":"","disable-sort":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"body",fn:function({ items }){return [_c('tbody',[(_vm.$vuetify.breakpoint.smAndUp)?_c('tr',{staticClass:"background-tr-color"},[_c('th',{class:`text-${_vm.extraordinarySchedulesHeaders[0].align}`},[_vm._v(" "+_vm._s(_vm.extraordinarySchedulesHeaders[0].text)+" ")]),_c('th',{class:`text-${_vm.extraordinarySchedulesHeaders[1].align}`},[_vm._v(" "+_vm._s(_vm.extraordinarySchedulesHeaders[1].text)+" ")]),_c('th',{class:`text-${_vm.extraordinarySchedulesHeaders[2].align}`},[_vm._v(" "+_vm._s(_vm.extraordinarySchedulesHeaders[2].text)+" ")]),_c('th',{class:`text-${_vm.extraordinarySchedulesHeaders[3].align}`},[_vm._v(" "+_vm._s(_vm.extraordinarySchedulesHeaders[3].text)+" ")]),_c('th',{class:`text-${_vm.extraordinarySchedulesHeaders[4].align}`},[_vm._v(" "+_vm._s(_vm.extraordinarySchedulesHeaders[4].text)+" ")])]):_vm._e(),_vm._l((items),function(item,index){return _c('tr',{key:index,class:[
          _vm.$vuetify.breakpoint.xsOnly
            ? 'v-data-table__mobile-table-row'
            : '',
        ]},[_c('td',{class:_vm.$vuetify.breakpoint.xsOnly
              ? 'v-data-table__mobile-row pt-6'
              : ''},[_c('div',{staticClass:"font-weight-bold mr-2 v-data-table__mobile-row__header d-flex d-sm-none"},[_vm._v(" "+_vm._s(_vm.extraordinarySchedulesHeaders[0].text)+" ")]),_c('div',{class:_vm.$vuetify.breakpoint.xsOnly
                ? 'v-data-table__mobile-row__cell'
                : `d-flex justify-${_vm.extraordinarySchedulesHeaders[0].align}`},[_vm._v(" "+_vm._s(item.hour)+" ")])]),_c('td',{class:_vm.$vuetify.breakpoint.xsOnly
              ? 'v-data-table__mobile-row pt-6'
              : ''},[_c('div',{staticClass:"font-weight-bold mr-2 v-data-table__mobile-row__header d-flex d-sm-none"},[_vm._v(" "+_vm._s(_vm.extraordinarySchedulesHeaders[1].text)+" ")]),_c('div',{class:_vm.$vuetify.breakpoint.xsOnly
                ? 'v-data-table__mobile-row__cell'
                : `d-flex justify-${_vm.extraordinarySchedulesHeaders[1].align}`},[_vm._v(" "+_vm._s(item.customer)+" ")])]),_c('td',{class:_vm.$vuetify.breakpoint.xsOnly
              ? 'v-data-table__mobile-row pt-6'
              : ''},[_c('div',{staticClass:"font-weight-bold mr-2 v-data-table__mobile-row__header d-flex d-sm-none"},[_vm._v(" "+_vm._s(_vm.extraordinarySchedulesHeaders[2].text)+" ")]),_c('div',{class:_vm.$vuetify.breakpoint.xsOnly
                ? 'v-data-table__mobile-row__cell'
                : `d-flex justify-${_vm.extraordinarySchedulesHeaders[2].align}`},[_vm._v(" "+_vm._s(item.phlebotomist)+" ")])]),_c('td',{class:_vm.$vuetify.breakpoint.xsOnly
              ? 'v-data-table__mobile-row pt-6'
              : ''},[_c('div',{staticClass:"font-weight-bold mr-2 v-data-table__mobile-row__header d-flex d-sm-none"},[_vm._v(" "+_vm._s(_vm.extraordinarySchedulesHeaders[3].text)+" ")]),_c('div',{class:_vm.$vuetify.breakpoint.xsOnly
                ? 'v-data-table__mobile-row__cell'
                : `d-flex justify-${_vm.extraordinarySchedulesHeaders[3].align}`},[_vm._v(" "+_vm._s(item.address)+" ")])]),_c('td',{class:_vm.$vuetify.breakpoint.xsOnly ? 'v-data-table__mobile-row pt-6' : ''},[_c('div',{staticClass:"font-weight-bold mr-2 v-data-table__mobile-row__header d-flex d-sm-none"},[_vm._v(" "+_vm._s(_vm.extraordinarySchedulesHeaders[4].text)+" ")]),_c('div',{class:_vm.$vuetify.breakpoint.xsOnly
                ? 'v-data-table__mobile-row__cell'
                : `d-flex justify-${_vm.extraordinarySchedulesHeaders[4].align}`},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2 mt-2",attrs:{"fab":"","dark":"","color":"orange","icon":"","small":"","to":`/citas/detalle-cita/${item.id}`}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-form-select ")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver detalle de cita")])])],1)])])})],2)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }